import { gql } from '@apollo/client'
import { graphql } from 'utils/gqlTada'

export const REFRESH_TOKEN = graphql(`
  query RefreshJWT {
    RefreshTokenQuery {
      id
      token
    }
  }
`)

export const REALTY_PLANS = gql`
  query RealtyPlansQuery {
    RealtyPlansQuery {
      id
      name
    }
  }
`

export const READ_MESSAGES_HISTOGRAM = gql`
  query MessagesHistogram(
    $startDate: String
    $endDate: String
    $companyId: Int
    $advertiserId: Int
    $brokerId: Int
    $realtyId: Int
    $includeMessagesWithBonus: Boolean
  ) {
    query: MessagesHistogram(
      startDate: $startDate
      endDate: $endDate
      companyId: $companyId
      advertiserId: $advertiserId
      brokerId: $brokerId
      realtyId: $realtyId
      includeMessagesWithBonus: $includeMessagesWithBonus
    ) {
      data {
        date: period
        count: amount
      }
    }
  }
`

export const READ_CLIENTS_HISTOGRAM = gql`
  query ClientsHistogram(
    $startDate: String
    $endDate: String
    $country: Int
    $state: Int
    $city: Int
    $neighborhoods: [Int]
  ) {
    query: ClientsHistogram(
      startDate: $startDate
      endDate: $endDate
      country: $country
      state: $state
      city: $city
      neighborhoods: $neighborhoods
    ) {
      data {
        date: period
        count: amount
      }
    }
  }
`

export const READ_ADDRESS_TYPES = gql`
  query AddressTypeQuery(
    $search: String
    $searchIn: String
    $order: String
    $limit: Int
    $page: Int
  ) {
    query: AddressTypeQuery(
      search: $search
      searchIn: $searchIn
      order: $order
      limit: $limit
      page: $page
    ) {
      total
      currentPage
      hasMorePages
      data {
        id
        name
      }
    }
  }
`

export const READ_PREFERRED_CONTACT = gql`
  query PreferredContactQuery(
    $search: String
    $searchIn: String
    $order: String
    $limit: Int
    $page: Int
  ) {
    query: PreferredContactQuery(
      search: $search
      searchIn: $searchIn
      order: $order
      limit: $limit
      page: $page
    ) {
      data {
        id
        name
      }
    }
  }
`

export const READ_API_TIMEZONE = gql`
  query ReadApiTimezone {
    query: DateTimeQuery
  }
`

export const READ_URL_TYPES = graphql(`
  query SettingUrlTypeQuery {
    query: UrlTypeQuery {
      data {
        id
        name
      }
    }
  }
`)
